import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import Select from "react-select";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL, FILE_VIEW_URL } from "../../constant";
import { toast } from "react-toastify";

const AddUser = (props) => {
  const navigate = useNavigate();

  const user_id = useParams();
  const userID = user_id.id;

  const parent_id = localStorage.getItem("admin_id");

  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  // const header = {
  //     headers: { "Authorization": `Bearer ${token}` }
  // }
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json"
    },
  };
  // console.log("token", token);
  const [errors, setErrors] = useState([]);
  const [inputValue, setInputValue] = useState({
    city: "Bareilly",
  });
  const [state, setState] = useState([]);
  const [stateData, setStateData] = useState({ label: "Uttar Pradesh" });
  const [country, setCountry] = useState([]);
  const [countryData, setCountryData] = useState({
    label: "India",
    value: "India",
  });
  const [gender, setGender] = useState("Male");
  const [inputImage, setInputImage] = useState({
    userImage: "",
  });
  const [branchDetails, setBranchDetails] = useState([]);

  const branchList = () => {
    axios.get(API_URL + "branch-list").then((res) => {
      setBranchDetails(res.data.data);
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handleGender = (e) => {
    const value = e.target.value;
    setGender(value);
  };

  const handleUserImage = (e, fieldName) => {
    const file = e.target.files[0];
    setInputImage({ ...inputImage, [fieldName]: file });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = isValid();
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, handle form submission here
      console.log("Form submitted successfully!");
    } else {
      // Form is invalid, display validation errors
      console.log("Form error found!");
      setErrors(validationErrors);
      return null;
    }

    setInputValue("");
    setGender("");
    setStateData("");
    setCountryData("");

    const formData = new FormData();
    formData.append("name", inputValue.name);
    formData.append("parent_id", parent_id);
    formData.append("email", inputValue.email);
    formData.append("contact_number", inputValue.contact_number);
    formData.append("city", inputValue.city);
    formData.append("zipcode", inputValue.zipcode);
    formData.append("address", inputValue.address);
    formData.append("gender", gender);
    formData.append("country", countryData.label);
    formData.append("state", stateData.label);
    formData.append("profile_image", inputImage.userImage);
    formData.append("branch_id_mapping", inputValue?.branch_id_mapping);

    // const forms = {
    //   name: inputValue.name,
    //   email: inputValue.email,
    //   contact_number: toString(inputValue.contact_number),
    //   city: inputValue.city,
    //   zipcode: inputValue.zipcode,
    //   address: inputValue.address,
    //   gender: gender,
    //   country: countryData.label,
    //   state: stateData.label,
    //   branch_id_mapping: inputValue.branch_name
    // };

    if (userID) {
      axios
        .put(API_URL + `user-update/${userID}`, formData, header)
        .then((res) => {
          toast.success("User updated successfully!");
          setTimeout(() => navigate("/user-list"), 4000);
        });
    } else {
      axios.post(API_URL + "add-user", formData, header).then((res) => {
        if (res.status == 200) {
          toast.success(res.message ? res.message : "User Added Successfully!");
          setTimeout(() => navigate("/user-list"), 4000);
        } else {
          toast.error("Error! Please try again.");
        }
      });
    }
  };

  //validation
  const isValid = () => {
    let errors = {};
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!inputValue.name) {
      errors.name = "Name is required";
    } else if (inputValue.name.trim().length < 3) {
      errors.name = "Name is too short";
    } else if (/[`~,.<>;':"/[\]|{}()=_+-]/.test(inputValue.name.trim())) {
      errors.name = "Special characters are not allowed!";
    }

    if (!inputValue.email) {
      errors.email = "Email is required";
    } else if (!inputValue.email.match(emailRegex)) {
      errors.email = "Invalid email format";
    }

    if (!inputValue.contact_number) {
      errors.contact_number = "Contact Number is required";
    } else if (inputValue.contact_number.length !== 10) {
      errors.contact_number = "Contact Number Should be exact 10 digits.";
    }

    if (!inputValue.city) {
      errors.city = "City is required";
    }

    if (!inputValue.zipcode) {
      errors.zipcode = "Zipcode is required";
    } else if (inputValue.zipcode.length !== 6) {
      errors.zipcode = "Zipcode should be six digits only.";
    }

    if (!gender) {
      errors.gender = "Gender is required";
    }

    // if (!inputImage) {
    //   errors.userImage = "Please upload image";
    // }

    // if(!inputValue.branch_id_mapping){
    //   errors.branch_name= 'Branch Name Required';
    // }

    return errors;
  };
  //end validation

  useEffect(() => {
    if (userID) {
      axios.get(API_URL + `user-details/${userID}`, header).then((res) => {
        setInputValue(res.data.users);
      });
    }
    axios.get(API_URL + "country-list").then((res) => {
      setCountry(res.data.data);
    });

    axios.get(API_URL + "state-list").then((res) => {
      setState(res.data.data);
    });
  }, []);

  const country_option = [];
  const state_option = [];
  const country_Data = country?.map((country_item) => {
    let country_obj = {
      label: country_item.name,
      value: country_item.name,
    };
    country_option.push(country_obj);

    const state_Data = state?.map((state_item) => {
      if (country_item.code == state_item.country_code) {
        let state_obj = { label: state_item.default_name };
        state_option.push(state_obj);
      }
    });
  });

  useEffect(() => {
    branchList();
  }, []);

  const newBranchName = branchDetails?.find((item) =>
    item.id == inputValue?.branch_id_mapping ? item.name : ""
  );

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  {!userID ? (
                    "Add User"
                  ) : (
                    <>
                      {" "}
                      <Link to="/user-list">
                        <i
                          className="mdi mdi-arrow-left me-2"
                          style={{ color: "#000" }}
                        ></i>
                      </Link>
                      Edit User
                    </>
                  )}
                </h4>
                <form className="forms-sample" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputUsername1">
                          Name <sapn style={{ color: "red" }}>*</sapn>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="name"
                          value={inputValue?.name}
                          onChange={handleInput}
                        />
                        {errors.name && (
                          <span className="validationErrors">
                            {errors.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Email address <sapn style={{ color: "red" }}>*</sapn>{" "}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={inputValue?.email}
                          onChange={handleInput}
                        />
                        {errors.email && (
                          <span className="validationErrors">
                            {errors.email}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group position-relative">
                        <label>
                          Password <sapn style={{ color: "red" }}>*</sapn>
                          <span style={{color:'gray', fontSize:'10px'}} >{" (Password Should be at least 8 digit) "}</span>
                        </label>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          value={inputValue.password}
                          onChange={handleInput}
                        />
                        <i className="mdi mdi-eye eyeIcon" onClick={hideShowValue}></i>
                        {errors.password && (
                          <span className="validationErrors">
                            {errors.password}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group position-relative">
                        <label className="">
                          Confirm Password{" "}
                          <sapn style={{ color: "red" }}>*</sapn>{" "}
                        </label>
                        <input
                          type={showConfPassword ? 'text' : 'password'}
                          className="form-control"
                          placeholder="Password"
                          name="confirm_password"
                          value={inputValue.confirm_password}
                          onChange={handleInput}
                        />
                         <i className="mdi mdi-eye eyeIcon" onClick={hideShowConfValue}></i>
                        {errors.confirm_password && (
                          <span className="validationErrors">
                            {errors.confirm_password}
                          </span>
                        )}
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputUsername1">
                          City <sapn style={{ color: "red" }}>*</sapn>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="CIty"
                          name="city"
                          value={inputValue?.city}
                          onChange={handleInput}
                        />
                        {errors.city && (
                          <span className="validationErrors">
                            {errors.city}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputUsername1">
                          {" "}
                          <sapn style={{ color: "red" }}>*</sapn>
                          Contact Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Contact Number"
                          name="contact_number"
                          value={inputValue?.contact_number}
                          onChange={handleInput}
                        />
                        {errors.contact_number && (
                          <span className="validationErrors">
                            {errors.contact_number}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Country</label>
                        <Select
                          value={countryData}
                          onChange={setCountryData}
                          options={country_option}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>State</label>
                        <Select
                          value={stateData}
                          onChange={setStateData}
                          options={state_option}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="">Address</label>
                        <textarea
                          className="form-control"
                          rows="1"
                          cols="1"
                          name="address"
                          value={inputValue?.address}
                          onChange={handleInput}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputUsername1">
                          Zip Code <sapn style={{ color: "red" }}>*</sapn>{" "}
                        </label>
                        <input
                          type="Number"
                          className="form-control"
                          placeholder="Zip Code"
                          name="zipcode"
                          value={inputValue?.zipcode}
                          onChange={handleInput}
                        />
                        {errors.zipcode && (
                          <span className="validationErrors">
                            {errors.zipcode}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Gender <sapn style={{ color: "red" }}>*</sapn>{" "}
                        </label>
                        <div className="row">
                          <div className="col-md-6 mt-2 gender_">
                            <input
                              type="radio"
                              name="gender"
                              value="Male"
                              id="male"
                              checked={gender == "Male"}
                              onChange={handleGender}
                            />{" "}
                            <label htmlFor="male">Male</label>
                          </div>

                          <div className="col-md-6 mt-2 gender_">
                            <input
                              type="radio"
                              name="gender"
                              value="Female"
                              id="female"
                              checked={gender == "Female"}
                              onChange={handleGender}
                            />{" "}
                            <label htmlFor="female">Female</label> <br />
                          </div>
                        </div>

                        {errors.gender && (
                          <span className="validationErrors">
                            {errors.gender}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Profile Image
                          {/* {inputValue.profile_image} */}
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name="userImage"
                          onChange={(e) => handleUserImage(e, "userImage")}
                        />

                        {/* {errors.userImage && (
                          <span className="validationErrors">
                            {errors.userImage}
                          </span>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="">Area</label>
                        <select
                          className="form-control"
                          style={{ height: "46px" }}
                          value={inputValue?.branch_id_mapping}
                          onChange={handleInput}
                          name="branch_id_mapping"
                        >
                          <option
                            value={
                              inputValue?.branch_id_mapping
                                ? inputValue?.branch_id_mapping
                                : ""
                            }
                          >
                            {inputValue?.branch_id_mapping
                              ? newBranchName?.name
                              : "Select One"}
                          </option>
                          {branchDetails?.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.branch_id_mapping && (
                          <span className="validationErrors">
                            {errors.branch_id_mapping}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        {/* <img src={`${FILE_VIEW_URL}${inputValue.profile_image}`} alt='profile' style={{height:'100px', width:'100px'}} /> */}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-gradient-primary me-2"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddUser;
