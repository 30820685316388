import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";
import Select from "react-select";

// import { API_URL } from "../../constant";

const EditLead = () => {
    const navigate = useNavigate();
    const Token = JSON.parse(localStorage.getItem("Login"));
    const token = Token.token;
    const header = {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
        },
    };

    const [inputData, setInputData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [errors, seterrors] = useState({});
    const [userListShow, setUserListShow] = useState([]);
    const [state, setState] = useState([]);
    const [country, setCountry] = useState([]);
    const [userListData, setUserListData] = useState("");

    const loan_id = useParams();
    const loanID = loan_id.id;

    const loanDetails = () => {
        axios.get(API_URL + `lead-details/${loanID}`, header).then((resp) => {
            setInputData(resp?.data?.data);

            let user_id = resp?.data?.data?.executive_id;
            axios.get(API_URL + `user-details/${user_id}`, header).then((resp) => {
                setUserData(resp?.data?.users);
            });
        });
    };

    const country_option = [];
    const state_option = [];
    const country_Data = country?.map((country_item) => {
        let country_obj = {
            label: country_item.code,
            value: country_item.name,
        };
        country_option.push(country_obj);

        const state_Data = state?.map((state_item) => {
            if (country_item.code == state_item.country_code) {
                let state_obj = { label: state_item.default_name };
                state_option.push(state_obj);
            }
        });
    });
    
    useEffect(() => {
        axios.get(API_URL + "country-list").then((res) => {
            setCountry(res.data.data);
        });

        axios.get(API_URL + "state-list").then((res) => {
            setState(res.data.data);
        });
        axios.get(API_URL + "user-list", header).then((res) => {
            setUserListShow(res?.data?.users);
        });
    }, []);

    useEffect(() => {
        loanDetails();

        //userDetails();
    }, []);

    const handleEditChange = (e) => {
        seterrors({})
        let { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
    };

    const updateLoanDetails = async () => {
        try {
            if (Validation()) {
                let updatedData = {
                    lead_id: loanID,
                    // loan_account_number: inputData.loan_account_number,
                    customer_name: inputData.customer_name,
                    mobile_no: inputData.mobile_no,
                    email: inputData.email,
                    country: inputData.country,
                    description: inputData.description,
                    address: inputData.address,
                    city: inputData.city,
                    member_id: inputData.member_id,
                    status: inputData.status,
                };

                await axios
                    .put(API_URL + `lead-update/${loanID}`, updatedData, header)
                    .then((res) => {

                        if (res.data.status == "true") {
                            toast.success(res.data.message);
                            setTimeout(() => {
                                navigate("/loan-details-bulk-upload");
                            }, 4000);
                        } else {
                            toast.error(res.data.message);
                        }
                    });
            }
        } catch (error) {
            console.error("Error updating loan details:", error);
            toast.error(
                "Error updating loan details. Please try again later.",
                error
            );
        }
    };
    const Validation = () => {
        let valid = true;
        const newErrors = {};
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
        const numericRegex = /\d/;

        if (inputData?.customer_name === "") {
            newErrors.customer_name = "Client name is required";
            valid = false;
        }
        if (inputData.email === "") {
            newErrors.email = "Email is required";
            valid = false;
            // setemailerror(true);
        } else if (!emailRegex.test(inputData.email)) {
            newErrors.email = "Please enter valid email Id";
            valid = false;
            //   setemailerror(true);
        }
        if (inputData.mobile_no === "") {
            newErrors.mobile_no = "Contact is required";
            valid = false;
        } else if (inputData.mobile_no.length !== 10) {
            newErrors.mobile_no = "Contact number should be 10 digits only";
            valid = false;
        }
        if (inputData?.address === "") {
            newErrors.address = "Address is required";
            valid = false;
        }

        seterrors(newErrors);
        return valid;
    }
    const userListOption = [];
    const userNameData = userListShow.map((item) => {
        let userName_obj = { label: item.name, value: item.id };
        userListOption.push(userName_obj);
    
    });


    return (
        <Layout>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">
                                {" "}
                                <Link to="/loan-details-bulk-upload">
                                    <i
                                        className="mdi mdi-arrow-left"
                                        style={{ color: "#000", paddingRight: "8px" }}
                                    ></i>
                                </Link>
                                Task Assign to User
                            </h4>
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Executive Name</label>
                                            <select
                                                className="form-control"
                                                style={{ height: "46px" }}
                                                value={inputData?.member_id
                                                }
                                                name="member_id"
                                                onChange={handleEditChange}

                                            >
                                                <option value="" disabled>
                                                    {" "}
                                                    Select Executive
                                                </option>
                                                {userListShow?.map((item, i) => (
                                                    <option key={i} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={inputData?.customer_name}
                                                placeholder="Client Name"
                                                onChange={handleEditChange}
                                                name="customer_name"
                                            />
                                            {(errors?.customer_name ? (
                                                <small className="validationErrors">{errors?.customer_name}</small>
                                            ) : (
                                                ""
                                            ))}
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Country</label>
                                            <select
                                                className="form-control"
                                                style={{ height: "46px" }}
                                                value={inputData?.country
                                                }
                                                name="country"
                                                onChange={handleEditChange}

                                            >
                                                <option value="" disabled>
                                                    {" "}
                                                    Select Country
                                                </option>
                                                {country_option?.map((item, i) => (
                                                    <option key={i} value={item.value}>
                                                        {item.value}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>State</label>

                                            <select
                                                className="form-control"
                                                style={{ height: "46px" }}
                                                value={inputData?.state
                                                }
                                                name="state"
                                                onChange={handleEditChange}

                                            >
                                                <option value="" disabled>
                                                    {" "}
                                                    Select State
                                                </option>
                                                {state_option?.map((item, i) => (
                                                    <option key={i} value={item.label}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="city"
                                                value={inputData?.city}
                                                onChange={handleEditChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={inputData?.email}
                                                onChange={handleEditChange}
                                                name="email"
                                                placeholder="Email"
                                            />
                                            {(errors?.email ? (
                                                <small className="validationErrors">{errors?.email}</small>
                                            ) : (
                                                ""
                                            ))}
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <textarea
                                                className="form-control"
                                                placeholder="Address"
                                                rows="1"
                                                cols="1"
                                                name="address"
                                                value={inputData?.address}
                                                onChange={handleEditChange}
                                            ></textarea>
                                            {(errors?.address ? (
                                                <small className="validationErrors">{errors?.address}</small>
                                            ) : (
                                                ""
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea
                                                className="form-control"
                                                rows="1"
                                                cols="1"
                                                placeholder="Description"
                                                name="description"
                                                value={inputData?.description}
                                                onChange={handleEditChange}
                                            ></textarea>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contact Number</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Contact Number"
                                                name="mobile_no"
                                                min={0}
                                                maxLength={10}
                                                value={inputData?.mobile_no}
                                                onChange={handleEditChange}
                                            />
                                            {(errors?.mobile_no ? (
                                                <small className="validationErrors">{errors?.mobile_no}</small>
                                            ) : (
                                                ""
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select
                                                className="form-control"
                                                style={{ height: "46px" }}
                                                name="status"
                                                onChange={handleEditChange}
                                                value={inputData?.status}
                                            >
                                                <option value="0">Inactive</option>
                                                <option value="1">Active</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    onClick={updateLoanDetails}
                                    className="btn btn-gradient-primary me-2"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditLead;
