import React from 'react'
import {Link} from 'react-router-dom'

export default function Forgot() {
  return (
    <div className="container-scroller">
    <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
                <div className="col-lg-4 mx-auto">
                    <div className="auth-form-light text-left p-5">
                        <div className="brand-logo">
                            {/* <img src="" /> */}
                        </div>
                        <h4>New here?</h4>
                        <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6>
                        <form className="pt-3">
                        <div className="form-group">
                  <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email" />
                </div>
                           
                            <div className="mt-3">
                                <button type='button' className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" >Submit</button>
                            </div>
                            <div className="text-center mt-4 font-weight-light"> <Link to="/" className="text-primary">Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- content-wrapper ends --> */}
    </div>
    {/* <!-- page-body-wrapper ends --> */}
</div>
  )
}
