import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import axios from "axios";
import { API_URL } from "../../constant";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AttendanceDetails = () => {
  const userID = useParams();
  const user_id = userID.user_id;
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data"
      "Content-Type": "application/json",
    },
  };

  const todayDate = new Date();
  const forDate = moment(todayDate).format("MM");
  console.log("date - ", forDate);
  const [attend, setAttend] = useState([]);

  const user_attandance = async () => {
    await axios
      .get(API_URL + `user-month-attends?id=${user_id}&month=${forDate}`)
      .then((res) => {
        setAttend(res.data.data);
      });
  };
  console.log("attend", attend);
  useEffect(() => {
    user_attandance();
  }, []);
  const total_hr_count = [];
  const userAttand_Name = localStorage.getItem("user_attandanceName");
  const total_hr = attend.map((hrs) => {
    total_hr_count.push(hrs.duration);
  });

  // calculate sum using forEach() method
  let time_array = ["01:30", "02:50", "01:50"];
  // let time_array = total_hr_count;
  let total_duration = 0;
  time_array.forEach((num) => {
    let hours_to_min, min_time;
    let time_split = num.split(":");
    if (time_split[0] > 0) {
      hours_to_min = parseInt(time_split[0]) * 60;
    }
    min_time = parseInt(time_split[1]);
    const total_mintus = hours_to_min + min_time;
    total_duration += total_mintus;
  });
  const totalHrs = Math.floor(total_duration / 60);
  const totalMin = total_duration % 60;
  // console.log("0000000",sum);

  //  console.log("hrs",time_array.reduce((a,b)=>a + b, 0));
  //  console.log("hrs=======",sum);

  // Filter by date and month
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const month_filter = moment(startDate).format("MM");
  const year_filter = moment(startDate).format("YYYY");

  const filter_month_year = async () => {
    await axios
      .get(
        API_URL +
          `user-month-attends?month=${month_filter}&year=${year_filter}&id=${user_id}`
      )
      .then((res) => {
        setFilteredData(res.data.data);
      });
  };

  useEffect(() => {
    filter_month_year();
  }, [month_filter, year_filter]);
  // end of Filter

  const date_match = moment(todayDate).format("YYYY-MM-DD");
  console.log("matching date --- ", date_match);

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="card-title mb-0">
                      <Link to="/attendance">
                        <i
                          className="mdi mdi-arrow-left me-2"
                          style={{ color: "#000" }}
                        ></i>
                      </Link>
                      Monthly Attandance{" "}
                      <span style={{ color: "#7502e7" }}>
                        ({userAttand_Name})
                      </span>
                    </h4>
                  </div>
                  <div className="col-md-6 text-end">
                    <div class="position-relative">
                      <i className="mdi mdi-filter filter_icon_date"></i>
                      <DatePicker
                        className="form-control datepicker-padding datepicker-style"
                        selected={startDate}
                        dateFormat="MM-yyyy"
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                      <i className="mdi mdi-calendar-today attendance_filter"></i>
                    </div>
                  </div>
                </div>

                <div className="table-responsive mt-3">
                  <Table striped bordered hover className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Login Count</th>
                        <th>Login Time</th>
                        <th>Logout Time</th>
                        <th>Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData?.length > 0 ? (
                        filteredData.map((val, index) => {
                          return (
                            <>
                            
                              <tr key={val.id} className={val.date == date_match ? "color_change" : ""}>
                                
                                <td>{index + 1}</td>
                                <td>{moment(val.date).format("DD-MM-YYYY")}</td>
                                <td>
                                  {val.login_count == 0 ? "-" : val.login_count}
                                </td>
                                <td>
                                  {val.login_count == 0
                                    ? "-"
                                    : moment(val.clockIn).format("hh:mm:ss A")}
                                </td>
                                <td>
                                  {val.login_count == 0 ||
                                  val.clockOut == "00:00"
                                    ? "-"
                                    : moment(val.clockOut).format("hh:mm:ss A")}
                                </td>
                                <td>
                                  {val.login_count == 0
                                    ? "-"
                                    : val.duration + " hrs "}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>No Data Found!</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AttendanceDetails;
