import React from "react";
import { Link } from "react-router-dom";


export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container-fluid d-flex justify-content-between">
          {/* <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">
            Copyright © bootstrapdash.com 2021
          </span>
          <span className="float-none float-sm-end mt-1 mt-sm-0 text-end">
            {" "}
            Free{" "}
            <a
              href="https://www.bootstrapdash.com/bootstrap-admin-template/"
              target="_blank"
            >
              Bootstrap admin template
            </a>{" "}
            from Bootstrapdash.com
          </span> */}

          <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">
            Copyright © 2023 HashTag Labs, All rights reserved.
          </span>
          <span className="float-none float-sm-end mt-1 mt-sm-0 text-end">
            {" "}
            {/* Paid{" "} */}
            {/* <a
              href="https://www.bootstrapdash.com/bootstrap-admin-template/"
              target="_blank"
            >
              Bootstrap admin template
            </a>{" "} */}
            <Link
              to="https://hashtaglabs.biz/"
              target="_blank"
            >
              Powered by HashTagLabs
            </Link>{" "}
            {/* from Bootstrapdash.com */}
          </span>
        </div>
      </footer>
    </>
  );
}
