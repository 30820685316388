import React, { Children } from 'react'
    import Navbar from './Navbar'
    import Sidebar from './Sidebar'
    import Footer from './Footer'
    import AddUser from './AddUser'

const Layout = ({children}) => {
     
    return (
        <div className="container-scroller">
    
            {/* <!-- navbar--> */}
            <Navbar />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">
                {/* <!-- partial:partials/_sidebar.html --> */}
                <Sidebar />
                {/* <!-- main content start --> */}
                <div className="main-panel">
                    <div className="content-wrapper">
                     
                       {children}
                  
                    </div>
                     
                    <Footer />
                </div>
                {/* main content end */}
            </div>
        </div>
    )
}

export default Layout