import React from "react";
import { Link } from "react-router-dom";

// import AddUser from './AddUser'


export default function Sidebar() {
  const handleSideBar = (active) => {
    localStorage.setItem("activeClass",active)
    }
    const addClass = localStorage.getItem('activeClass');
    // console.log("addClass",addClass);
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav" >
       
        <li className="sidebarScroll position-fixed">
          <ul>
            <li className={addClass == 1 ? "nav-item active" : "nav-item"} onClick={(e)=>handleSideBar("1")}>
              <Link className="nav-link" to="/dashboard">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">Dashboard</span>
              </Link>
            </li>
          
            <li className={addClass == 2 ? "nav-item active" : "nav-item"} onClick={(e)=>handleSideBar("2")}>
              <Link className="nav-link" to="/adduser">
                <i className="mdi mdi-account-plus menu-icon"></i>
                <span className="menu-title">Add User</span>
              </Link>
            </li>

            <li className={addClass == 3 ? "nav-item active" : "nav-item"} onClick={(e)=>handleSideBar("3")}>
              <Link className="nav-link" to="/user-list">
                <i className="mdi mdi-account-multiple menu-icon"></i>
                <span className="menu-title">User List</span>
              </Link>
            </li>

            <li className={addClass == 4 ? "nav-item active" : "nav-item"} onClick={(e)=>handleSideBar("4")}>
              <Link className="nav-link" to="/loan-details-bulk-upload">
                <i className="mdi mdi-account menu-icon"></i>
                <span className="menu-title">Task Assign</span>
              </Link>
            </li>

            <li className={addClass == 6 ? "nav-item active" : "nav-item"} onClick={(e)=>handleSideBar("6")}>
              <Link className="nav-link" to="/t-user-survey-list">
                <i className="mdi mdi-account-card-details menu-icon"></i>
                <span className="menu-title">Date Wise Survey</span>
              </Link>
            </li>

            <li className={addClass == 5 ? "nav-item active" : "nav-item"} onClick={(e)=>handleSideBar("5")}>
              <Link className="nav-link" to="/user-survey-list">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">All Survey</span>
              </Link>
            </li>

            <li className={addClass == 7 ? "nav-item active" : "nav-item"} onClick={(e)=>handleSideBar("7")}>
              <Link className="nav-link" to="/attendance">
                <i className="mdi mdi-human-greeting menu-icon"></i>
                <span className="menu-title">Attendance</span>
              </Link>
            </li>

            <li className={addClass == 8 ? "nav-item active" : "nav-item"} onClick={(e)=>handleSideBar("8")}>
              <Link className="nav-link" to="/branch-details">
                <i className="mdi mdi-call-split menu-icon"></i>
                <span className="menu-title">Branch</span>
              </Link>
            </li>
            

            
          </ul>
        </li>
      </ul>
    </nav>
  );
}
