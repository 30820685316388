import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import axios from "axios";
import { API_URL } from "../../constant";
import { Link } from "react-router-dom";

const Dashboard = () => {
  //headers
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
    },
  };

  const [dashboardData, setDashboardData] = useState({});

  const dashboardValue = async () => {
    await axios.get(API_URL + 'admin-dashbaord', header).then((res) => {
      setDashboardData(res.data.data);
    });
  };


  useEffect(() => {
    dashboardValue();
  }, []);

  return (
    <Layout>
      <>
        <div className="page-header">
          <h3 className="page-title">
            <span className="page-title-icon bg-gradient-primary text-white me-2">
              <i className="mdi mdi-home"></i>
            </span>{" "}
            Dashboard
          </h3>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <span></span>Overview{" "}
                <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-3 stretch-card grid-margin">
            <div className="card bg-gradient-danger card-img-holder text-white">
              <Link to="/user-list" className="dashboard_item_link">
                <div className="card-body">
                  <img
                    src="assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle-image"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Active Users{" "}
                    <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                  </h4>
                  <h2>{dashboardData?.activeUsersCount}</h2>
                  {/* <h6 className="card-text">Increased by 60%</h6> */}
                </div>
              </Link>
            </div>
          </div>

          <div className="col-md-3 stretch-card grid-margin">
            <div className="card bg-gradient-info card-img-holder text-white">
            <Link to="/user-survey-list" className="dashboard_item_link">
              <div className="card-body">
                <img
                  src="assets/images/dashboard/circle.svg"
                  className="card-img-absolute"
                  alt="circle-image"
                />
                <h4 className="font-weight-normal mb-3">
                  Total Survey{" "}
                  <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                </h4>
                <h2>{dashboardData?.totalSurvey}</h2>
              </div>
              </Link>
            </div>
          </div>

          <div className="col-md-3 stretch-card grid-margin">
            <div className="card bg-gradient-success card-img-holder text-white">
            <Link to="/loan-details-bulk-upload" className="dashboard_item_link">
              <div className="card-body">
                <img
                  src="assets/images/dashboard/circle.svg"
                  className="card-img-absolute"
                  alt="circle-image"
                />
                <h4 className="font-weight-normal mb-3">
                  Total Task{" "}
                  <i className="mdi mdi-diamond mdi-24px float-right"></i>
                </h4>
                <h2>{dashboardData?.totalTask}</h2>
              </div>
              </Link>
            </div>
          </div>

          <div className="col-md-3 stretch-card grid-margin">
            <div className="card bg-gradient-warning card-img-holder text-white">
            <Link to="/loan-details-bulk-upload" className="dashboard_item_link">
              <div className="card-body">
                <img
                  src="assets/images/dashboard/circle.svg"
                  className="card-img-absolute"
                  alt="circle-image"
                />
                <h4 className="font-weight-normal mb-3">
                  Open Task{" "}
                  <i className="mdi mdi-diamond mdi-24px float-right"></i>
                </h4>
                <h2>{dashboardData?.openTask}</h2>
              </div>
              </Link>
            </div>
          </div>

          <div className="col-md-3 stretch-card grid-margin">
            <div className="card bg-gradient-success card-img-holder text-white">
              <div className="card-body">
                <img
                  src="assets/images/dashboard/circle.svg"
                  className="card-img-absolute"
                  alt="circle-image"
                />
                <h4 className="font-weight-normal mb-3">
                  Closed Task{" "}
                  <i className="mdi mdi-diamond mdi-24px float-right"></i>
                </h4>
                <h2>{dashboardData?.closedTask}</h2>
              </div>
            </div>
          </div>

          <div className="col-md-3 stretch-card grid-margin">
            <div className="card bg-gradient-success card-img-holder text-white">
              <div className="card-body">
                <img
                  src="assets/images/dashboard/circle.svg"
                  className="card-img-absolute"
                  alt="circle-image"
                />
                <h4 className="font-weight-normal mb-3">
                  Folow Up Task{" "}
                  <i className="mdi mdi-diamond mdi-24px float-right"></i>
                </h4>
                <h2>{dashboardData?.followUpTasks}</h2>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Dashboard;
