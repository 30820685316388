import React from "react";
import axios from "axios";
import Layout from "./Layout";
import { API_URL } from "../../constant";

import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UserSurveyList = () => {
  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data"
      "Content-Type": "application/json",
    },
  };
  const [task, setTask] = useState([]);

  // filter by month
  const [startDate, setStartDate] = useState(new Date());
  const month_filter = moment(startDate).format("MM");
  const year_filter = moment(startDate).format("YYYY");

  //End filter

  useEffect(() => {
    axios
      .get(
        API_URL + `task-wise-survey?month=${month_filter}&year=${year_filter}`,
        header
      )
      .then((res) => {
        setTask(res.data.data);
      });
  }, [month_filter, year_filter]);

  const handleViewTask = (executive_id, executive_name) => {
    localStorage.setItem("executive_name", executive_name);
    localStorage.setItem("user_ID", executive_id);

    localStorage.setItem("survey_month_filter", month_filter);
    localStorage.setItem("survey_year_filter", year_filter);
    
    navigate(
      `/survey-task-list/${executive_id}/${month_filter}/${year_filter}`
    );
  };

  // pagination start
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;

  const currentItems = task.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(task.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % task.length;

    setItemOffset(newOffset);
  };
  // pagination end

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="card-title">User Survey List</h4>
                  </div>
                  <div className="col-md-6" style={{ textAlign: "end" }}>
                    {/* <Link to="#" className="link_to_add_branch" onClick={csv_download}>
                      Export to CSV
                    </Link> */}
                    <div class="position-relative">
                      <i className="mdi mdi-filter filter_icon_date"></i>
                      <DatePicker
                        className="form-control datepicker-padding datepicker-style"
                        selected={startDate}
                        dateFormat="MM-yyyy"
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                      <i className="mdi mdi-calendar-today attendance_filter"></i>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <Table striped bordered hover className="table mt-3">
                    <thead>
                      <tr>
                        <th>Executive Id</th>
                        <th>Executive Name</th>
                        {/* <th>New Task</th> */}
                        <th>All Task</th>
                        <th>Follow Up Task</th>
                        <th>Close Task</th>
                        {/* <th>Month Task</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {task.length == "0" && (
                        <tr>
                          <td colSpan="8" className="text-center p-0">
                            <ColorRing
                              visible={true}
                              height="40"
                              width="40"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          </td>
                        </tr>
                      )}
                      {currentItems.map((task_val) => {
                        return (
                          <tr>
                            <td>{task_val.executive_id}</td>
                            <td>{task_val.name}</td>
                            {/* <td>{task_val.totalNewTask}</td> */}
                            <td>{task_val.totalAssignTask}</td>
                            <td>{task_val.totalFollowUpTask}</td>
                            <td>{task_val.totalCloseTask}</td>
                            {/* <td>{task_val.totalMonthTask}</td> */}
                            <td>
                              <i
                                className="mdi mdi-eye"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleViewTask(
                                    task_val.executive_id,
                                    task_val.name
                                  )
                                }
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                {/* pagination */}
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination mt-3 justify-content-end"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default UserSurveyList;
