import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../constant";
import { toast } from "react-toastify";

const EditBranchDetails = () => {
  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
    },
  };

  const branch_id = useParams();
  const branchID = branch_id.id;

  const [countryName, setCountryName] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [branchData, setBranchData] = useState([]);

  const singleBranchDetails = async () => {
    await axios.get(API_URL + `branch-details/${branchID}`).then((res) => {
      setBranchData(res.data.data);
    });
  };

  const handleEditChange = (e) => {
    let { name, value } = e.target;
    setBranchData({ ...branchData, [name]: value });
  };

  const country_name = async () => {
    await axios.get(API_URL + "country-list").then((res) => {
      setCountryName(res.data.data);
    });
  };

  const aall = () => {
    if (branchData.country == "IN") {
      axios.get(API_URL + "state-list").then((resp) => {
        setStateName(resp.data.data);
      });
    } else {
      setStateName([]);
      // console.log("not found");
    }
  };

  const hitcountry = () => {
    aall();
  };

  useEffect(() => {
    country_name();
    singleBranchDetails();
  }, []);

  const updateBranchDetails = (e) => {
    e.preventDefault();
    const data = {
      name: branchData.name,
      branch_code: branchData.branch_code,
      address: branchData.address,
      city: branchData.city,
      country: branchData.country,
      state: branchData.state,
      zipcode: branchData.zipcode,
      status: branchData.status,
    };

    axios.put(API_URL + `edit-branch/${branchID}`, data).then((res) => {
      if (res) {
        toast.success("Branch updated successfully!");
        setTimeout(() => {
          navigate("/branch-details");
        }, 4000);
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <Link to="/branch-details">
                    <i
                      className="mdi mdi-arrow-left me-2"
                      style={{ color: "#000" }}
                    ></i>
                  </Link>
                  Edit Branch Details
                </h4>

                <div className="forms-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Name</label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleEditChange}
                          className="form-control"
                          defaultValue={branchData.name}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Code</label>
                        <input
                          type="text"
                          name="branch_code"
                          onChange={handleEditChange}
                          className="form-control"
                          defaultValue={branchData.branch_code}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          name="address"
                          onChange={handleEditChange}
                          className="form-control"
                          defaultValue={branchData.address}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          name="city"
                          onChange={handleEditChange}
                          className="form-control"
                          defaultValue={branchData.city}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Country</label>
                        <select
                          className="form-control"
                          style={{ height: "46px" }}
                          name="country"
                          onChange={handleEditChange}
                          onClick={hitcountry}
                          value={branchData.country}
                        >
                          <option value=""> ------ Select One ------</option>
                          {countryName.map((item, i) => (
                            <option key={i} value={item.code}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>State</label>
                        <select
                          className="form-control"
                          style={{ height: "46px" }}
                          name="state"
                          onChange={handleEditChange}
                          value={branchData.state}
                        >
                          <option value={branchData.state}>
                            {" "}
                            {branchData.state}{" "}
                          </option>
                          {stateName.map((item, i) => (
                            <option key={i} value={item.code}>
                              {item.default_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Zipcode</label>
                        <input
                          type="text"
                          name="zipcode"
                          onChange={handleEditChange}
                          className="form-control"
                          defaultValue={branchData.zipcode}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      {/* <div className="form-group">
                        <label>Status</label>
                        <input
                          type="text"
                          name="status"
                          onChange={handleEditChange}
                          className="form-control"
                          defaultValue={branchData.status}
                        />
                      </div> */}
                      <div className="form-group">
                        <label>Status</label>
                        <select
                          name="status"
                          className="form-control"
                          style={{ height: "46px" }}
                          onChange={handleEditChange}
                        >
                          <option value={branchData.status}>
                            {branchData.status == 1 ? "Active" : "Inactive"}
                          </option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={updateBranchDetails}
                    className="btn btn-gradient-primary me-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default EditBranchDetails;
