import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate,Link } from "react-router-dom";
import { API_URL } from "../../constant";

export default function Navbar() {
  const navigate = useNavigate();

  const userLoginEmail = JSON.parse(localStorage.getItem("Login"));
  const admin_id1 = localStorage.getItem("admin_id");

  const handleSubmit = () => {
    axios.post(API_URL + `logout/${admin_id1}`).then((res) => {
      localStorage.clear();
      setTimeout(() => navigate("/"), 300);
    });
  };
  
  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src="/team-icon.png" alt="logo" /> <span className="teamTrackLogo">TeamTrack</span>
          </Link> 
          <Link className="navbar-brand brand-logo-mini" to="index.html">
            <img src="/assets/images/logo-mini.svg" alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center p-0">
         
          <div className="company-logo">
          {/* <p className="content-logo">Ranu Enterprises</p> */}
          </div>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="profileDropdown"
                to="/login"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nav-profile-img">
                  <img src="/assets/images/faces/face1.jpg" alt="face" />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">{userLoginEmail.email}</p>
                </div>
              </Link>
              <div
                className="dropdown-menu navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
              
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={handleSubmit}>
                  <i className="mdi mdi-logout me-2 text-primary"></i> Signout{" "}
                </button>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </>
  );
}
