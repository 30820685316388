// import logo from './logo.svg';
// require('dotenv').config()
// import dotenv from '.env'
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Login from "./Components/AuthPage/Login";
import Register from "./Components/AuthPage/Register";
import Forgot from "./Components/AuthPage/Forgot";
import Layout from "./Components/Profile/Layout";
import AddUser from "./Components/Profile/AddUser";
import Dashboard from "./Components/Profile/Dashboard";
import UserList from "./Components/Profile/UserList";
import UserBulkUpload from "./Components/Profile/UserBulkUpload";
// import LoanDetailsBulkUpload from './Components/Profile/LoanDetailsBulkUpload';
import BulkUploadLoanDetails from "./Components/Profile/BulkUploadLoanDetails";
import EditLead from "./Components/Profile/EditLead";
import SurveyList from "./Components/Profile/SurveyList";

import Attendence from "./Components/Profile/Attendance";
import AttendanceDetails from "./Components/Profile/AttendanceDetails";

import ManageBranch from "./Components/Profile/Branch/ManageBranch";
import AddNewBranch from "./Components/Profile/Branch/AddNewBranch";
import EditBranchDetails from "./Components/Profile/Branch/EditBranchDetails";
import UserSurveyList from "./Components/Profile/UserSurveyList";
import SurveyTaskList from "./Components/Profile/SurveyTaskList";

import TUserSurveyList from "./Components/Profile/TUserSurveyList";
import TUserSurveyDetails from "./Components/Profile/TUserSurveyDetails";

import Location from "./Components/Profile/Location";
import LocationAll from "./Components/Profile/LocationAll";


function App() {
  return (
    <>
      <Routes>
        <Route path="/main" element={<Layout />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="*" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<Forgot />} />

        <Route path="/adduser" element={<AddUser />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user-list" element={<UserList />} />
        <Route path="/edituser/:id" element={<AddUser />} />
        <Route path="/adduser" element={<AddUser />} />

        <Route path="/user-bulk-upload" element={<UserBulkUpload />} />
        <Route
          path="/loan-details-bulk-upload"
          element={<BulkUploadLoanDetails />}
        />
        <Route path="/editLead/:id" element={<EditLead />} />

        <Route path="/survey-list-view/:id" element={<SurveyList />} />

        <Route path="/attendance" element={<Attendence />} />
        <Route path="/attandance-details/:user_id" element={<AttendanceDetails />} />

        <Route path="/branch-details" element={<ManageBranch />} />
        <Route path="/add-new-branch" element={<AddNewBranch />} />
        <Route path="/edit-branch-details/:id" element={<EditBranchDetails />} />
        <Route path="/user-survey-list" element={<UserSurveyList />} />
        <Route exact path="/survey-task-list/:id/:month_filter/:year_filter" element={<SurveyTaskList />} />

       <Route path="/t-user-survey-list" element={<TUserSurveyList />} />
       <Route path="/t-user-survey-details" element={<TUserSurveyDetails />} />

       <Route path="/location/:latitude/:longitude/:customer_name" element={<Location />} />
       <Route path="/Location-all/:latitude/:longitude/:customer_name/:id" element={<LocationAll />} />

      </Routes>
      <ToastContainer position="top-right" autoClose={3000} />
      </>
  );
}

export default App;
