import React from "react";
import Layout from "./Layout";
import { Link, useParams, useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";

// const AnyReactComponent = ({ text }) => (
//   <div
//     style={{
//       color: "white",
//       background: "grey",
//       padding: "15px 10px",
//       display: "inline-flex",
//       textAlign: "center",
//       alignItems: "center",
//       justifyContent: "center",
//       borderRadius: "100%",
//       transform: "translate(-50%, -50%)",
//     }}
//   >
//     {text}
//   </div>
// );

const LocationAll = () => {
  const paramsData = useParams();
  const latitude = parseFloat(paramsData.latitude);
  const longitude = parseFloat(paramsData.longitude);

  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 10,
    // Marker: {
    //     position: {lat: 26.8803799, lng: 80.988259},
    //     title: "Hello World!",
    //   }
  };

  function renderMarkers(map, maps) {
    let marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      title: paramsData.customer_name,
    });
  }

  return (
    <Layout>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="card-title">
                    <Link to={`/survey-list-view/${paramsData.id}`}>
                      <i
                        className="mdi mdi-arrow-left me-2"
                        style={{ color: "#000" }}
                      ></i>
                    </Link>
                    Survey Details{" "}
                    <span style={{ color: "#7502e7" }}>
                      ({paramsData.customer_name})
                    </span>
                  </h4>
                </div>
                <div className="col-md-6" style={{ textAlign: "end" }}></div>
              </div>
              {/* // Important! Always set the container height explicitly */}
              <div style={{ height: "100vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "" }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  onGoogleApiLoaded={({ map, maps }) =>
                    renderMarkers(map, maps)
                  }
                >
                  {/* <AnyReactComponent
          lat={26.8803799}
          lng={80.988259}
        /> */}
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LocationAll;
