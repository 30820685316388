import React from "react";
import axios from "axios";
import Layout from "./Layout";
import { API_URL, FILE_VIEW_URL } from "../../constant";

import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import ReactPaginate from "react-paginate";

const TUserSurveyDetails = () => {
  const executive_id = localStorage.getItem("today_user_ID");
  const executive_name = localStorage.getItem("today_executive_name");
  const filter_date = localStorage.getItem("toady_filter_date");

  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data"
      "Content-Type": "application/json",
    },
  };

  const today_date = new Date();
  const todayDate = moment(today_date).format("YYYY-MM-DD");

  const [task, setTask] = useState([]);

  useEffect(() => {
    axios
      .get(
        API_URL +
          `user-today-survey?today_date=${filter_date}&user_id=${executive_id}`,
        header
      )
      .then((res) => {
       
        setTask(res.data.data);
      });
  }, []);

  // pagination start
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = task.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(task.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % task.length;
    setItemOffset(newOffset);
  };
  // pagination end

  const displayLocation = (latitude, longitude, customer_name) => {
      navigate(`/location/${latitude}/${longitude}/${customer_name}`);
  };


  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="card-title">
                      <Link to="/t-user-survey-list">
                        <i
                          className="mdi mdi-arrow-left me-2"
                          style={{ color: "#000" }}
                        ></i>
                      </Link>
                      Survey Details{" "}
                      <span style={{ color: "#7502e7" }}>
                        ({executive_name})
                      </span>
                    </h4>
                  </div>
                  <div className="col-md-6" style={{ textAlign: "end" }}></div>
                </div>

                <div className="table-responsive">
                  <Table striped bordered hover className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Customer Name</th>
                        {/* <th>Account Number</th>
                        <th>Branch</th>
                        <th>Visit Mode</th>
                        <th>Reason</th> */}
                        <th width="10%">Feedback</th>
                        {/* <th>Promise Amount</th> */}
                        <th>Status</th>
                        <th>Client Photo</th>
                        <th>User Photo</th>
                        {/* <th>Location </th> */}
                        <th>Follow-up Date</th>
                        <th>Visit Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {task?.length > 0 ? (
                        task?.map((task_val, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{task_val.customer_name}</td>
                              {/* <td>{task_val.acc_number}</td>
                              <td>{task_val.branch_name}</td>
                              <td>{task_val.visit_mode}</td>
                              <td>{task_val.disposition_code}</td> */}
                              <td
                                className="ellipsis_value"
                                title={task_val.feedback_remarks}
                              >
                                {task_val.feedback_remarks}
                              </td>

                              {/* <td>{"₹ " + task_val.promise_amount}</td> */}
                              <td>
                                {task_val.status == "Completed" ? (
                                  <span className="badge rounded-pill bg-success text-white">
                                    {task_val.status}
                                  </span>
                                ) : (
                                  <span className="badge rounded-pill bg-danger text-white">
                                    {task_val.status}
                                  </span>
                                )}
                              </td>

                              <td>
                                <img
                                  data-bs-toggle="modal"
                                  data-bs-target={
                                    "#exampleModalClient" + index + 1
                                  }
                                  src={`${FILE_VIEW_URL}${task_val.client_photo}`}
                                  alt="Client Photo"
                                  title="Client Photo"
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "1px",
                                  }}
                                />
                              </td>
                              {/* Client Photo */}
                              <div
                                class="modal fade"
                                id={"exampleModalClient" + index + 1}
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5
                                        class="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        User Photo
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      <img
                                        className="w-100"
                                        src={`${FILE_VIEW_URL}${task_val.client_photo}`}
                                        alt="profile"
                                        style={{ borderRadius: "1px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* End of client photo  */}

                              <td>
                                <img
                                  data-bs-toggle="modal"
                                  data-bs-target={"#exampleModal" + index + 1}
                                  src={`${FILE_VIEW_URL}${task_val.user_photo}`}
                                  alt="User Photo"
                                  title="User Photo"
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "1px",
                                  }}
                                />
                              </td>
                              {/* User Photo */}
                              <div
                                class="modal fade"
                                id={"exampleModal" + index + 1}
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5
                                        class="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        User Photo
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      <img
                                        className="w-100"
                                        src={`${FILE_VIEW_URL}${task_val.user_photo}`}
                                        alt="profile"
                                        style={{ borderRadius: "1px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* End of user photo  */}

                              {/* <td>
                                {task_val.latitude !== "" &&
                                task_val.longitude !== "" ? (
                                  <p
                                    style={{color:'blue', cursor:'pointer'}}
                                    onClick={() =>
                                      displayLocation(
                                        task_val.latitude,
                                        task_val.longitude,
                                        task_val.customer_name
                                      )
                                    }
                                  >
                                    User Location
                                  </p>
                                ) : (
                                  <p>User Location</p>
                                )}
                              </td> */}
                              <td>
                                {moment(task_val.followup_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>
                                {moment(task_val.visit_date).format(
                                  "DD-MM-YYYY hh:mm:ss A"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11}>No Data Found!</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                {/* pagination */}
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination mt-4 justify-content-end"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
        {/* img modal */}
        {/* <!-- Button trigger modal --> */}
        {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> */}
      </Layout>
    </>
  );
};
export default TUserSurveyDetails;
