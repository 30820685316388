import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import axios from "axios";
import { API_URL } from "../../constant";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";

const Attendence = () => {
  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data"
      "Content-Type": "application/json",
    },
  };

  const todayDate = new Date();
  const forDate = moment(todayDate).format("YYYY-MM-DD");
  const Today_Date = moment(todayDate).format("DD-MM-YYYY");
  // console.log("date - ", forDate);
  const [attend, setAttend] = useState([]);
  const [searchField, setSearchField] = useState("");

  const user_attandance = async () => {
    await axios.get(API_URL + `user-attends?date=${forDate}`).then((res) => {
      setAttend(res.data.data);
    });
  };

  // var myDate = attend[0]?.clockOut;
  // var clockin_date = moment(myDate).format('YYYY-MM-DD hh:mm:ss A');
  // // var status_date = moment(clockin_date).add({hours:5, minutes: 30}).format('YYYY-MM-DD hh:mm:ss A');
  // console.log("attend -", myDate);

  useEffect(() => {
    user_attandance();
  }, []);
  const handleSearch = (e) => {
    setSearchField(e.target.value);
  };

  const FIlterData = attend.filter((item) =>
    // console.log("99999999",item?.user?.name)
    item.user_name.toLowerCase().includes(searchField.toLowerCase())
  );

  const [searchDate, setSearchDate] = useState(new Date());
  const handleDate = (e) => {
    const dddd = moment(e).format("DD-MM-yyyy");
    setSearchDate(dddd);
  };

  const handleViewattendance = (id, user_attandanceName) => {
    localStorage.setItem("user_attandanceName", user_attandanceName);
    navigate(`/attandance-details/${id}`);
  };
  // console.log("todayDate",Today_Date);
  // pagination start
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = FIlterData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(FIlterData.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % FIlterData.length;
    setItemOffset(newOffset);
  };
  // pagination end

 

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0">
                    User Attandance <span style={{color:"#7502e7"}}>({Today_Date})</span>
                  </h4>
                  <div className="ms-3 d-inline-flex">
                    {/* <DatePicker
                    name="search_date"
                    onChange={handleDate}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    selected={searchDate}
                    // onChange={(date) => setStartDate(date)}
                  /> */}
                    
                  </div>
                  <div className="search-field ms-3 d-inline-flex">
                    <span class="mdi mdi-magnify"></span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Type to Search..."
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="table-responsive mt-4">
                  <Table striped bordered hover className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>User ID</th>
                        <th>Name</th>
                        <th>Login Count</th>
                        <th>Login Time</th>
                        <th>Logout Time</th>
                        <th>Duration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attend.length == "0" ? (
                        <tr>
                          <td colSpan="8" className="text-center p-0">
                            <ColorRing
                              visible={true}
                              height="40"
                              width="40"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          </td>
                        </tr>
                      ) : attend?.length > 0 ? (
                        currentItems.map((val, index) => {
                          return (
                            <tr key={val.id}>
                              <td>{index + 1}</td>
                              <td>{val.user_id}</td>
                              <td>
                                {/* <Link
                                  to={`/attandance-details/${val.user_id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  {val.user_name}
                                </Link> */}
                                {val.user_name}
                              </td>
                              <td>
                                {val.login_count == 0 ? "-" : val.login_count}
                              </td>
                              <td>
                                {val.login_count == 0
                                  ? "-"
                                  : moment(val.clockIn).format("hh:mm:ss A")}
                              </td>
                              <td>
                                {val.login_count == 0 || val.clockOut == "00:00"
                                  ? "-"
                                  : moment(val.clockOut).format("hh:mm:ss A")}
                              </td>
                              <td>
                                {val.login_count == 0
                                  ? "-"
                                  : val.duration + " hrs "}
                              </td>
                              <td>
                                <i
                                  className="mdi mdi-eye"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewattendance(
                                      val.user_id,
                                      val.user_name
                                    )
                                  }
                                ></i>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>No Data Found!</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                {/* pagination */}
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination mt-3 justify-content-end"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Attendence;
