import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { API_URL } from "../../constant";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
// import {tableimg} from '/assets/images/table_img/tableimg.png'
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";
import Papa from "papaparse";

const LoanDetailsBulkUpload = () => {
  const navigate = useNavigate();
  const [loanDetails, setLoanDetails] = useState([]);
  const [state, setState] = useState([]);
  const [userList, setUserList] = useState([]);
  const [errors, seterrors] = useState({});
  const [inputValue, setInputValue] = useState({
    clientName: "",
    contact: "",
    address: "",
    city: "",
    email: "",
    description: ""
  });

  const [countryData, setCountryData] = useState({
    label: "India",
    value: "India",
  });
  const [stateData, setStateData] = useState({ label: "Uttar Pradesh" });
  const [userData, setUserData] = useState(null);
  const [country, setCountry] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setInputValue({
      clientName: "",
      contact: "",
      address: "",
      city: "",
      email: "",
      description: ""
    });

    setUserData('');
    seterrors({});
  }

  const handleShow = () => setShow(true);
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json"
    },
  };

 
  //List of loan details
  const loanDetailsList = async () => {
    try {
      await axios.get(API_URL + "lead-list", header).then((resp) => {
        setLoanDetails(resp.data.data);
      });
    } catch (error) {
      console.error(error);
      // alert("Error to fetch loan details.");
    }
  };



  useEffect(() => {
    loanDetailsList();
  
  }, []);

  //Edit loan details
  const handleEdit = (id) => {
    navigate(`/editLead/${id}`);
  };
  const handleDelete = async (id) => {
    try {
      await axios.get(API_URL + `lead-delete/${id}`, header)
        .then((res) => {
          if (res.data.status == true) {
            
            toast.success(res.data.message);
            loanDetailsList();
          }
          else {
            toast.warning(res.data.message)
          }

        })
    } catch (error) {
      console.log("error", error);

    }
  }
  const handleSearch = (e) => {
    setSearchField(e.target.value);
  };

  const FIlterData = loanDetails.filter((item) =>
    item.customer_name.toLowerCase().includes(searchField.toLowerCase())
  );

  // pagination start
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = FIlterData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(FIlterData.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % FIlterData.length;
    setItemOffset(newOffset);
  };
  // pagination end
  // add excel field start
  const handleInput = (e) => {
    seterrors({})
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const country_option = [];
  const user_option = [];
  const state_option = [];
  const country_Data = country.map((country_item) => {
    let country_obj = {
      label: country_item.name,
      value: country_item.name,
    };
    country_option.push(country_obj);

    const state_Data = state.map((state_item) => {
      if (country_item.code == state_item.country_code) {
        let state_obj = { label: state_item.default_name };
        state_option.push(state_obj);
      }
    });
  });
  const userList_Data = userList.map((item) => {
    let user_obj = {
      label: item.name,
      value: item.id,
    };
    user_option.push(user_obj);
  });
  useEffect(() => {
    axios.get(API_URL + "country-list").then((res) => {
      setCountry(res.data.data);
    });

    axios.get(API_URL + "state-list").then((res) => {
      setState(res.data.data);
    });
    axios.get(API_URL + "user-list", header).then((res) => {
      setUserList(res.data.users);
    });
  }, []);


  const handleExcelFieldSubmit = async (e) => {
    e.preventDefault();

    if (Validation()) {
      const data = {
        customer_name: inputValue.clientName,
        mobile_no: inputValue.contact,
        email: inputValue.email,
        country: countryData.value,
        city: inputValue.city,
        state: stateData.label,
        description: inputValue.description,
        member_id: userData.value,
        address: inputValue.address,
      }

      axios.post(API_URL + `create-lead`, data, header)
        .then((res) => {
          if (res.data.status == true) {
            toast.success(res.data.message, { autoClose: 2000 });
            setTimeout(() => {
              handleClose();
              loanDetailsList();
            }, 2000)


          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: 1000,
          });
        });

    }
  };

  const Validation = () => {
    let valid = true;
    const newErrors = {};
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    const numericRegex = /\d/;

    if (inputValue?.clientName === "") {
      newErrors.clientName = "Client name is required";
      valid = false;
    }
    if (inputValue?.email === "") {
      newErrors.email = "Email is required";
      valid = false;
      // setemailerror(true);
    } else if (!emailRegex.test(inputValue?.email)) {
      newErrors.email = "Please enter valid email Id";
      valid = false;
      //   setemailerror(true);
    }
    if (inputValue?.contact === "") {
      newErrors.contact = "Contact is required";
      valid = false;
    } else if (inputValue?.contact.length !== 10) {
      newErrors.contact = "Contact number should be 10 digits only";
      valid = false;
    }
    if (inputValue?.address === "") {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (userData == "") {
      newErrors.userData = "User is required";
      valid = false;
    }
    seterrors(newErrors);
    return valid;
  }
  // add excel field end

  return (
    <Layout>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-md-6">
                  <h4
                    className="card-title"
                    style={{ fontSize: "18px", fontWeight: "700" }}
                  >
                    Task
                  </h4>
                </div>
                <div className="col-md-6 text-end">
                  <button
                    type="submit"
                    className="btn btn-gradient-primary me-2"
                    onClick={handleShow}
                  >
                    Add New Task
                  </button>
                </div>

              </div>

              <div className="row ">
                <div className="col-md-6">
                  <div className="search-field">
                    <span class="mdi mdi-magnify"></span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Type to Search..."
                      onChange={handleSearch}
                    />
                  </div>
                </div>

              </div>

              <div className="table-responsive mt-4 taskAssignTable">
                <Table striped bordered hover className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Customer Name</th>
                      <th>Mobile No.</th>
                      <th>Country</th>
                      <th>State</th>
                      <th>City</th>
                      <th>Executive Name</th>
                      <th>Address</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loanDetails.length > 0 ? (
                      currentItems.map((val, index) => {
                        return (
                          <tr key={val.id}>
                            {loanDetails.map((cur, ind) => {
                              if (cur.id == val.id) {
                                return <td>{ind + 1}</td>;
                              }
                            })}
                            <td>{val.customer_name}</td>
                            <td>{val.mobile_no}</td>
                             <td>
                              {val?.country ? val.country : ""}{" "}
                            </td> 
                            <td>{val.state}</td>
                            <td>{val.city}</td>
                             <td>
                              {val?.member_id
                                ? val?.user?.name
                                : "-"}
                            </td>
                            <td>{val.address}</td>
                            <td>{val.description}</td>
                            <td>
                              {val.status === 0
                                ? <span className="badge rounded-pill bg-success text-white">Completed</span>
                                : val.status === 1
                                  ? <span className="badge rounded-pill bg-danger text-white">Open</span>
                                  : val.status === 2
                                    ? <span className="badge rounded-pill bg-warning text-white">Follow Up</span>
                                  : "-"}
                            </td>
                           
                            <td>
                              <span
                                className="mdi mdi-pencil enditLoanDetails"
                                onClick={() => handleEdit(val.id)}
                              ></span>
                              <span
                                className="mdi mdi-delete enditLoanDetails ms-2"
                                onClick={() => handleDelete(val.id)}
                              ></span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={13}>
                          No Data Found!
                          {/* <ColorRing
                            visible={true}
                            height="40"
                            width="40"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={[
                              "#e15b64",
                              "#f47e60",
                              "#f8b26a",
                              "#abbd81",
                              "#849b87",
                            ]}
                          /> */}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {/* add excel field modal start */}
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                className="addExcelModel"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add New Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form className="forms-sample" onSubmit={handleExcelFieldSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputUsername1">
                            Client Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Client Name"
                            name="clientName"
                            value={inputValue.clientName}
                            onChange={handleInput}
                          />

                          {(errors?.clientName ? (
                            <small className="validationErrors">{errors?.clientName}</small>
                          ) : (
                            ""
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputUsername1">

                            Contact Number
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Contact Number"
                            name="contact"
                            min={0}
                            maxLength={10}
                            value={inputValue.contact}
                            onChange={handleInput}
                          />
                          {(errors?.contact ? (
                            <small className="validationErrors">{errors?.contact}</small>
                          ) : (
                            ""
                          ))}
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputUsername1">
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="CIty"
                            name="city"
                            value={inputValue.city}
                            onChange={handleInput}
                          />
                          {/* {errors.city && (
                          <span className="validationErrors">
                            {errors.city}
                          </span>
                        )} */}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          <Select
                            value={countryData}
                            onChange={setCountryData}
                            options={country_option}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>State</label>

                          <Select

                            value={stateData}
                            onChange={setStateData}
                            options={state_option}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Email address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={inputValue.email}
                            onChange={handleInput}
                          />
                          {(errors?.email ? (
                            <small className="validationErrors">{errors?.email}</small>
                          ) : (
                            ""
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="">Description</label>
                          <textarea
                            className="form-control"
                            rows="1"
                            cols="1"
                            placeholder="Description"
                            name="description"
                            value={inputValue.description}
                            onChange={handleInput}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="">Address</label>
                          <textarea
                            className="form-control"
                            placeholder="Address"
                            rows="1"
                            cols="1"
                            name="address"
                            value={inputValue.address}
                            onChange={handleInput}
                          ></textarea>
                          {(errors?.address ? (
                            <small className="validationErrors">{errors?.address}</small>
                          ) : (
                            ""
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Select User</label>
                          <Select
                            labelField="name"
                            value={userData}
                            onChange={setUserData}
                            options={user_option}
                            placeholder="Select a user"
                          />
                          {(errors?.userData ? (
                            <small className="validationErrors">{errors?.userData}</small>
                          ) : (
                            ""
                          ))}
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-gradient-primary me-2"
                    >
                      Submit
                    </button>
                  </form>
                </Modal.Body>

              </Modal>
              {/* add excel field modal end */}
              {/* pagination */}
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination mt-4 justify-content-end"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoanDetailsBulkUpload;
