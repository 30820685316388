import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Layout from "./Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constant";
import ReactPaginate from 'react-paginate';
import { ColorRing } from 'react-loader-spinner'
const UserList = () => {
  const navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  const [userListShow, setUserListShow] = useState([]);
  const [searchField, setSearchField] = useState('');
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
  };
  const [branchName, setBranchName] = useState();

  const branch_name = () => {
    axios.get(API_URL + 'branch-list').then((res) => {
      setBranchName(res.data.data)
    })
  }


  useEffect(() => {
    axios.get(API_URL + "user-list", header).then((res) => {
      setUserListShow(res.data.users);
      branch_name();
      setLoading(false);
    });
  }, []);

  const handleEdit = (id) => {
    navigate(`/edituser/${id}`);
  };

  const handleSearch = (e) => {
    setSearchField(e.target.value);
  }

  const FIlterData = userListShow.filter((item) =>
    (item.name.toLowerCase().includes(searchField.toLowerCase())) ||
    (item.email.toLowerCase().includes(searchField.toLowerCase()))
  );
  // pagination start
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;


  const currentItems = FIlterData.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(FIlterData.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % FIlterData.length;

    setItemOffset(newOffset);
  };
  // pagination end
  return (
    <Layout>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="card-title d-inline-flex align-items-center h-100">User List</h3>
                </div>
                <div className="col-md-6">
                  <div className="search-field ms-auto">
                    <span class="mdi mdi-magnify"></span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Type to Search..."
                      onChange={handleSearch}
                    />
                  </div>
                </div>
               
                <div className="table-responsive mt-3">
                  <Table striped bordered hover className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Contact Number</th>
                        <th>Gender</th>
                        <th>Address</th>
                        <th>Area Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                
                      {
                        userListShow.length == "0" && (
                          <tr>
                          <td colSpan="8" className="text-center">
                              <ColorRing
                              visible={true}
                              height="40"
                              width="40"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                            </td>
                            </tr>
                        )
                      }
                      {currentItems.map((userList, index) => {
                        const newBranchName = branchName?.find(item => item.id === userList.branch_id_mapping);
                        return (
                          <tr>

                            {
                              userListShow.map((cur, ind) => {
                                if (cur.id == userList.id) {
                                  return (
                                    <td>{ind + 1}</td>
                                  )
                                }

                              })
                            }
                            <td>{userList.name}</td>
                            <td>{userList.email}</td>
                            <td>{userList.contact_number}</td>
                            <td>{userList.gender}</td>
                            <td>{userList.address + ', ' + userList.city + ', ' + userList.state}</td>
                            <td>{userList.branch_id_mapping ?  newBranchName?.name : '-'}</td>
                            <td>
                              <span
                                style={{cursor:'pointer'}}
                                className="mdi mdi-pencil"
                                onClick={() => handleEdit(userList.id)}
                              ></span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* pagination */}
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination mt-3 justify-content-end"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserList;
