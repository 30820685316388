import axios from "axios";
import Layout from "./Layout";
import { API_URL, FILE_VIEW_URL } from "../../constant";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Link, useParams, useNavigate } from "react-router-dom";

const SurveyList = () => {
  const navigate = useNavigate();

  const task_ID = useParams();
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data"
      "Content-Type": "application/json",
    },
  };

  const [list, setList] = useState([]);

  // const [exportCSV, setExportCSV] = useState("");
  const task_options = [
    { label: "New Task", value: "3" },
    { label: "Assigned Task", value: "" },
    { label: "Follow Up Task", value: "2" },
    { label: "Close Task", value: "0" },
    { label: "Month Task", value: "4" },
  ];

  useEffect(() => {
    axios.get(API_URL + `survey-list/${task_ID.id}`, header).then((res) => {
      setList(res.data.data);
    });
  }, []);

  // export csv fronend
  const headers = [
    { label: "Account Number", key: "acc_number" },
    { label: "Branch Name", key: "branch_name" },
    { label: "Reason (Disposition code)	", key: "disposition_code" },
    { label: "Executive Name", key: "executive_name" },
    { label: "Feedback/Remarks (Details Remark)", key: "feedback_remarks" },
    { label: "PTP Date/Next Visit Date", key: "followup_date" },
    { label: "PTP Amount", key: "promise_amount" },
    { label: "Visit Date", key: "visit_date" },
  ];

  const flattenedData = list?.map((item) => ({
    acc_number: String(item.acc_number),
    branch_name: item.branch_name,
    disposition_code: item.disposition_code,
    executive_name: item.executive_name,
    feedback_remarks: item.feedback_remarks,
    followup_date: item.followup_date,
    promise_amount: item.promise_amount,
    visit_date: moment(item.visit_date).format("DD-MM-YYYY hh:mm:ss a"),
  }));

  const csvReport = {
    data: flattenedData,
    headers: headers,
    filename: "data1.csv",
  };
  //end

  //export CSV from API
  const csv_download = async () => {
    await axios
      .get(API_URL + `survey-export/${task_ID.id}`, header)
      .then((res) => {
        window.open(API_URL + "/download");
      });
  };
  // end
  const userId = localStorage.getItem("user_ID");
  const customer_name = localStorage.getItem("customerName");
  const month_filter = localStorage.getItem("survey_month_filter");
  const year_filter = localStorage.getItem("survey_year_filter");


  const displayLocation = (latitude, longitude, customer_name, id) => {
    navigate(`/location-all/${latitude}/${longitude}/${customer_name}/${id}`);
  };


  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-4">
                    <h4 className="card-title mb-0 w-100 h-100 d-flex align-items-center">
                      <Link
                        to={`/survey-task-list/${userId}/${month_filter}/${year_filter}`}
                      >
                        {" "}
                        <i
                          className="mdi mdi-arrow-left"
                          style={{ color: "#000", paddingRight: "8px" }}
                        ></i>
                      </Link>
                      Survey List{" "}
                      <span style={{ color: "#7502e7" }}>
                        ({customer_name})
                      </span>
                    </h4>
                  </div>
                  <div className="col-md-4"></div>
                  {/* <div className="col-md-4" style={{ textAlign: "end" }}>
                    <Link
                      to="#"
                      className="link_to_add_branch d-inline-block"
                      onClick={csv_download}
                    >
                      Export to CSV
                    </Link>
                  </div> */}
                </div>

                {/* code for csv download */}
                {/* <CSVLink {...csvReport}>Export to CSV</CSVLink> */}

                <div className="table-responsive mt-3">
                  <Table striped bordered hover className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        {/* <th>Account Number</th> */}
                        <th>Customer Name</th>
                        {/* <th>Reason (Disposition code)</th> */}
                        {/* <th>Executive Name</th> */}
                        <th>Feedback/Remarks (Details Remark)</th>
                        {/* <th>PTP Amount</th> */}
                        <th>Status</th>
                        <th>Client Photo</th>
                        <th>User Photo</th>
                        {/* <th>Location</th> */}
                        <th>Next Visit Date</th>
                        <th>Visit Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list?.length > 0 ? (
                        list.map((val, index) => {
                          return (
                            <tr key={val.id}>
                              <td>{index + 1}</td>
                           
                              <td>{val.customer_name}</td>
                              {/* <td>{val.disposition_code}</td> */}
                              {/* <td>
                                {val.executive_name ? val.executive_name : "-"}
                              </td> */}
                              <td
                                className="ellipsis_value"
                                title={val.feedback_remarks}
                              >
                                {val.feedback_remarks
                                  ? val.feedback_remarks
                                  : "-"}
                              </td>

                              {/* <td>{"₹ " + val.promise_amount}</td> */}
                              <td>
                                {val.status == "Completed" ? (
                                  <span className="badge rounded-pill bg-success text-white">
                                    {val.status}
                                  </span>
                                ) : (
                                  <span className="badge rounded-pill bg-danger text-white">
                                    {val.status}
                                  </span>
                                )}
                              </td>

                              <td>
                                <img
                                  data-bs-toggle="modal"
                                  data-bs-target={
                                    "#exampleModalClient" + index + 1
                                  }
                                  src={
                                    val.client_photo == ""
                                      ? "sdfsdfsd"
                                      : `${FILE_VIEW_URL}${val.client_photo}`
                                  }
                                  alt="Client Photo"
                                  title="Client Photo"
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "1px",
                                  }}
                                />
                              </td>
                              {/* Client Photo */}
                              <div
                                class="modal fade"
                                id={"exampleModalClient" + index + 1}
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5
                                        class="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        User Photo
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      <img
                                        className="w-100"
                                        src={`${FILE_VIEW_URL}${val.client_photo}`}
                                        alt="profile"
                                        style={{ borderRadius: "1px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* End of client photo  */}

                              <td>
                                <img
                                  data-bs-toggle="modal"
                                  data-bs-target={"#exampleModal" + index + 1}
                                  src={
                                    val?.user_photo
                                      ? `${FILE_VIEW_URL}${val?.user_photo}`
                                      : "-"
                                  }
                                  alt="User Photo"
                                  title="User Photo"
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "1px",
                                  }}
                                />
                              </td>
                              {/* User Photo */}
                              <div
                                class="modal fade"
                                id={"exampleModal" + index + 1}
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5
                                        class="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        User Photo
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      <img
                                        className="w-100"
                                        src={`${FILE_VIEW_URL}${val.user_photo}`}
                                        alt="profile"
                                        style={{ borderRadius: "1px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* End of user photo  */}

                              {/* <td>
                                {val.latitude !== "" &&
                                val.longitude !== "" ? (
                                  <p
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() =>
                                      displayLocation(
                                        val.latitude,
                                        val.longitude,
                                        val.customer_name,
                                        task_ID.id
                                      )
                                    }
                                  >
                                    User Location
                                  </p>
                                ) : (
                                  <p>User Location</p>
                                )}
                              </td> */}

                              <td>
                                {val.followup_date == "0000-00-00"
                                  ? "-"
                                  : moment(val.followup_date).format(
                                      "DD-MM-YYYY"
                                    )}
                              </td>
                              <td>
                                {moment(val.visit_date).format(
                                  "DD-MM-YYYY hh:mm:ss a"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={13}>No Data Found!</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default SurveyList;
