import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import axios from "axios";
import { API_URL } from "../../../constant";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const AddNewBranch = () => {
  const [inputData, setInputData] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    let { name, value } = e.target;
    console.log(name);
    setInputData({ ...inputData, [name]: value });
  };

  const country_name = async () => {
    await axios.get(API_URL + "country-list").then((res) => {
      setCountryName(res.data.data);
    });
  };

  const aall = () => {
    if (inputData.country == "IN") {
      axios.get(API_URL + "state-list").then((resp) => {
        setStateName(resp.data.data);
      });
    } else {
      setStateName([]);
      // console.log("not found");
    }
  };

  const hitcountry = () => {
    aall();
  };

  useEffect(() => {
    country_name();
  }, [inputData]);

  const addNewBranchDetails = (e) => {
    e.preventDefault();

    // const validationErrors = isValid();
    // console.log('validationErrors  ',validationErrors);
    // if (Object.keys(validationErrors).length === 0) {
    //   console.log("Form submitted successfully!");
    // } else {
    //   console.log("Form error found!");
    //   setErrors(validationErrors);
    //   return null;
    // }

    let errors = {};
    if (!inputData.name) {
      errors.name = "Name is required";
    } else if (inputData.name.trim().length < 3) {
      errors.name = "Name is too short";
    }

    if (!inputData.branch_code) {
      errors.branch_code = "Branch Code Required";
    }

    if (!inputData.address) {
      errors.address = "Address field is Required";
    }

    if (!inputData.city) {
      errors.city = "City Field Required";
    }

    if (!inputData.country) {
      errors.country = "Country Field Required";
    }

    if (!inputData.state) {
      errors.state = "State Field Required";
    }

    if (!inputData.zipcode) {
      errors.zipcode = "Zip code Required";
    }

    if (!inputData.status) {
      errors.status = "Status Required";
    }

    if (Object.keys(errors).length > 0) {
      // console.log("error found",Object.keys(errors).length);
      setErrors(errors);
      return false;
    }

    const data = {
      name: inputData.name,
      branch_code: inputData.branch_code,
      address: inputData.address,
      city: inputData.city,
      country: inputData.country,
      state: inputData.state,
      zipcode: inputData.zipcode,
      status: inputData.status,
    };

    axios.post(API_URL + "add-branch", data).then((res) => {
      if (res) {
        toast.success("Branch added successfully!");
        setTimeout(() => {
          navigate("/branch-details");
        }, 4000);
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  //validations
  // const isValid = () => {
  //   let errors = {};
  //   console.log('errors --------',inputData.name);
  //   if (!inputData.name) {
  //     errors.name = "Name is required";
  //   } else if (inputData.name.trim().length < 3) {
  //     errors.name = "Name is too short";
  //   }
  // }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <Link to="/branch-details">
                    <i
                      className="mdi mdi-arrow-left me-2"
                      style={{ color: "#000" }}
                    ></i>
                  </Link>
                  Add New Branch
                </h4>

                <div className="forms-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Name</label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          className="form-control"
                        />
                        {errors.name && (
                          <span className="validationErrors">
                            {errors.name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Code</label>
                        <input
                          type="text"
                          name="branch_code"
                          onChange={handleChange}
                          className="form-control"
                        />
                        {errors.branch_code && (
                          <span className="validationErrors">
                            {errors.branch_code}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          name="address"
                          onChange={handleChange}
                          className="form-control"
                        />
                        {errors.address && (
                          <span className="validationErrors">
                            {errors.address}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          name="city"
                          onChange={handleChange}
                          className="form-control"
                        />
                        {errors.city && (
                          <span className="validationErrors">
                            {errors.city}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Country</label>
                        <select
                          className="form-control"
                          style={{ height: "46px" }}
                          name="country"
                          onChange={handleChange}
                          onClick={hitcountry}
                        >
                          <option value=""> ------ Select One ------</option>
                          {countryName.map((item, i) => (
                            <option key={i} value={item.code}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.country && (
                          <span className="validationErrors">
                            {errors.country}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>State</label>
                        <select
                          className="form-control"
                          style={{ height: "46px" }}
                          name="state"
                          onChange={handleChange}
                        >
                          <option value=""> ------ Select One ------</option>
                          {stateName.map((item, i) => (
                            <option key={i} value={item.code}>
                              {item.default_name}
                            </option>
                          ))}
                        </select>
                        {errors.state && (
                          <span className="validationErrors">
                            {errors.state}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Zipcode</label>
                        <input
                          type="text"
                          name="zipcode"
                          onChange={handleChange}
                          className="form-control"
                        />
                        {errors.zipcode && (
                          <span className="validationErrors">
                            {errors.zipcode}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      {/* <div className="form-group">
                        <label>Status</label>
                        <input
                          type="text"
                          name="status"
                          onChange={handleChange}
                          className="form-control"
                        />
                        {errors.status && (
                          <span className="validationErrors">
                            {errors.status}
                          </span>
                        )}
                      </div> */}

                      <div className="form-group">
                        <label>Status</label>
                        <select
                          name="status"
                          style={{ height: "46px" }}
                          onChange={handleChange}
                          className="form-control"
                        > 
                          <option>-- Select Status --</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                        {errors.status && (
                          <span className="validationErrors">
                            {errors.status}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={addNewBranchDetails}
                    className="btn btn-gradient-primary me-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddNewBranch;
