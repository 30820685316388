// export const API_URL = "http://localhost:4000/api/";
// export const FILE_VIEW_URL = "http://localhost:4000/";  


// export const API_URL = "https://teamtrack.getanapp.co.in/api/";
// export const FILE_VIEW_URL = "https://teamtrack.getanapp.co.in/api/";

//  export const API_URL = "http://192.168.1.50:8000/api/";
//  export const FILE_VIEW_URL = "http://192.168.1.50:8000/";



export const API_URL = "http://htl.demowithme.com:8000/api/";
export const FILE_VIEW_URL = "http://htl.demowithme.com:8000/api/";