import React from "react";
import axios from "axios";
import Layout from "./Layout";
import { API_URL } from "../../constant";

import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UserSurveyList = () => {
  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data"
      "Content-Type": "application/json",
    },
  };

  const [startDate, setStartDate] = useState(new Date());
  const date_for_filter = moment(startDate).format("YYYY-MM-DD");

  const [task, setTask] = useState([]);
  useEffect(() => {
    axios
      .get(API_URL + `today-survey?today_date=${date_for_filter}`, header)
      .then((res) => {
        //   console.log("res task wise survey",res);
        setTask(res.data.data);
      });
  }, [startDate]);

  const handleViewTask = (executive_id, executive_name, filter_date) => {
    localStorage.setItem("today_executive_name", executive_name);
    localStorage.setItem("today_user_ID", executive_id);
    localStorage.setItem("toady_filter_date", filter_date);
    navigate(`/t-user-survey-details`);
  };

  console.log("start date ", moment(startDate).format("DD-MM-YYYY"));

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="card-title mb-0">
                      Survey List <span style={{color:'#7502e7'}}> ({moment(startDate).format("DD-MM-YYYY")}) </span> 
                    </h4>
                  </div>
                  <div className="col-md-6" style={{ textAlign: "end" }}>
                    <div class="position-relative">
                      <i className="mdi mdi-filter filter_icon_date"></i>
                      <DatePicker
                        className="form-control datepicker-padding datepicker-style"
                        selected={startDate}
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                      />
                      <i className="mdi mdi-calendar-today filter_icon_calender"></i>
                    </div>
                  </div>
                </div>

                <div className="table-responsive mt-3">
                  <Table striped bordered hover className="table">
                    <thead>
                      <tr>
                        <th>Executive Id</th>
                        <th>Executive Name</th>
                        <th>Assigned Task</th>
                        <th>Today Visit</th>
                        <th>Close Task</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {task?.length > 0 ? (
                        task?.map((task_val) => {
                          return (
                            <tr>
                              <td>{task_val.userid}</td>
                              <td>{task_val.name}</td>
                              <td>{task_val.assign}</td>
                              <td>{task_val.todayVisit}</td>
                              <td>{task_val.completed}</td>
                              <td>
                                <i
                                  className="mdi mdi-eye"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewTask(
                                      task_val.userid,
                                      task_val.name,
                                      date_for_filter
                                    )
                                  }
                                ></i>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={6}>No Data Found!</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default UserSurveyList;
