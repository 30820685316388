import axios from "axios";
import Layout from "./Layout";
import { API_URL } from "../../constant";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ListGroupItem } from "react-bootstrap";
// import { CSVLink } from "react-csv";

const SurveyTaskList = () => {
  const filter_data = useParams();

  const user_id = filter_data?.id;
  const filter_month = filter_data?.month_filter;
  const filter_year = filter_data?.year_filter;


  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data"
      "Content-Type": "application/json",
    },
  };

  const [list, setList] = useState([]);

  // const [exportCSV, setExportCSV] = useState("");
  const task_options = [
    { label: "New Task", value: "3" },
    { label: "Assigned Task", value: "" },
    { label: "Follow Up Task", value: "2" },
    { label: "Close Task", value: "0" },
    { label: "Month Task", value: "4" },
  ];

  const [taskStatus, setTaskStatus] = useState(task_options[1]);

  useEffect(() => {
    const statusTask = taskStatus.value == "" ? "" : taskStatus.value;
    axios
      .get(
        API_URL +
          `user-task-list?user_id=${user_id}&status=${statusTask}&month=${filter_month}&year=${filter_year}`,
        header
      )
      .then((res) => {
        setList(res.data.data);
      });
  }, [taskStatus]);



  // export csv fronend
  const headers = [
    { label: "Account Number", key: "acc_number" },
    { label: "Branch Name", key: "branch_name" },
    { label: "Reason (Disposition code)	", key: "disposition_code" },
    { label: "Executive Name", key: "executive_name" },
    { label: "Feedback/Remarks (Details Remark)", key: "feedback_remarks" },
    { label: "PTP Date/Next Visit Date", key: "followup_date" },
    { label: "PTP Amount", key: "promise_amount" },
    { label: "Visit Date", key: "visit_date" },
  ];

  const flattenedData = list?.map((item) => ({
    acc_number: String(item.acc_number),
    branch_name: item.branch_name,
    disposition_code: item.disposition_code,
    executive_name: item.executive_name,
    feedback_remarks: item.feedback_remarks,
    followup_date: item.followup_date,
    promise_amount: item.promise_amount,
    visit_date: moment(item.visit_date).format("DD-MM-YYYY hh:mm:ss a"),
  }));

  const csvReport = {
    data: flattenedData,
    headers: headers,
    filename: "data1.csv",
  };
  //end

  //export CSV from API
  const csv_download = async () => {
    await axios.get(API_URL + "survey-export", header).then((res) => {
      window.open(API_URL + "/download");
    });
  };
  // end
  const executive_name = localStorage.getItem("executive_name");

  const handleSurveyView = (taskid, customer_name) => {
    localStorage.setItem("customerName", customer_name);
    navigate(`/survey-list-view/${taskid}`);
  };

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="card-title mb-0 w-100 h-100 d-flex align-items-center">
                      <Link to="/user-survey-list">
                        {" "}
                        <i
                          className="mdi mdi-arrow-left"
                          style={{ color: "#000", paddingRight: "8px" }}
                        ></i>
                      </Link>
                      User Task{" "}
                      <span style={{ color: "#7502e7" }}>
                        ({executive_name})
                      </span>
                    </h4>
                  </div>
                  <div className="col-md-5">
                    <Select
                      placeholder={task_options[1].label}
                      onChange={setTaskStatus}
                      defaultValue={task_options[1].label}
                      options={task_options}
                    />
                  </div>
                  {/* <div className="col-md-2" style={{ textAlign: "end" }}>
                    <Link
                      to="#"
                      className="link_to_add_branch d-inline-block"
                      onClick={csv_download}
                    >
                      Export to CSV
                    </Link>
                  </div> */}
                </div>

                {/* code for csv download */}
                {/* <CSVLink {...csvReport}>Export to CSV</CSVLink> */}

                <div className="table-responsive mt-3">
                  <Table striped bordered hover className="table">
                    <thead>
                      <tr>
                        <th>Task Id</th>
                        <th>User Name</th>
                        <th>Mobile</th>
                        <th>No. of Visit</th>
                        <th>Last Visit Date</th>
                        <th>Next Visit</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.length > 0 ? (
                        list.map((val, index) => {
                          return (
                            <tr key={val.executive_id}>
                              <td>{val.task_id}</td>
                              <td>{val.user_name}</td>
                              <td>{val.mobile_no}</td>
                           
                              <td>{val.visit_count}</td>
                              <td>
                                {val.last_visit
                                  ? moment(val.last_visit).format("DD-MM-YYYY")
                                  : "-"}
                              </td>
                              <td>
                                {val.followup_date == "0000-00-00" ? "-" : val.followup_date
                                  ? moment(val.followup_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td>
                                {val.status == "Completed" ? (
                                  <span className="badge rounded-pill bg-success text-white">
                                    {val.status}
                                  </span>
                                ) : (
                                  <span className="badge rounded-pill bg-danger text-white">
                                    {val.status}
                                  </span>
                                )}
                              </td>
                              <td>
                                <p
                                  className="mb-0"
                                  onClick={() =>
                                    handleSurveyView(val.task_id, val.user_name)
                                  }
                                >
                                  <i
                                    className="mdi mdi-eye"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={13}>No Data Found!</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default SurveyTaskList;
