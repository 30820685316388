import React, { useState } from "react";
import Layout from "./Layout";
import { API_URL } from "../../constant";
import axios from "axios";

const UserBulkUpload = () => {
  const [file, setFile] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const Token = JSON.parse(localStorage.getItem("Login"));
  const token = Token.token;
  const header = {
    headers: { Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data"
    // "Content-Type": "application/json"
    },
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async () => {
    if (!file) {
      alert("Please select a CSV file to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);

      // Replace 'API_ENDPOINT' with your server endpoint to handle the file upload
    //   const response = await axios.post("http://localhost:4000/api/bulk-user-add", formData
      const response = await axios.post(
        API_URL + "upload-loan-details",
        formData,
        header
      );
      // Assuming the server returns a success message after processing the CSV file
      const data = response;
      console.log('data',data);
      alert("Users uploaded successfully!");
    } catch (error) {
      console.error("Error uploading users:", error);
      alert("Error uploading users. Please try again later.");
    }
  };

  return (
    <Layout>
      <div className="row">
        <div className="col-md-10 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Upload Excel Sheet of User</h4>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <input
                    type="file"
                    className="add-user"
                    // accept=".xls,.xlsx,.csv"
                    onChange={handleFileChange}
                  />
                  {isError && <div className="error-text">{errorMsg}</div>}
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    type="submit"
                    className="btn btn-gradient-primary me-2"
                    onClick={handleSubmit}
                  >
                    Upload
                  </button>
                  {isSuccess && (
                    <div className="success-text">Valid File Type</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserBulkUpload;
