import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../Layout";
import { API_URL } from "../../../constant";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const ManageBranch = () => {
  const navigate = useNavigate();
  const [branchDetails, setBranchDetails] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [countryName, setCountryName] = useState([]);
  const [stateName, setStateName] = useState([]);

  const branchList = async () => {
    await axios.get(API_URL + "branch-list").then((res) => {
      setBranchDetails(res.data.data);
    });
  };

  const countryList = async () => {
    await axios.get(API_URL + "country-list").then((res) => {
      setCountryName(res.data.data);
    });
  };

  const stateList = async () => {
    await axios.get(API_URL + "state-list").then((res) => {
      setStateName(res.data.data);
    });
  };

  useEffect(() => {
    countryList();
    stateList();
  }, []);

  console.log("branch country & state data ", countryName, stateName);

  useEffect(() => {
    branchList();
  }, []);

  //edit
  const handleEdit = (id) => {
    navigate(`/edit-branch-details/${id}`);
  };
  const handleSearch = (e) => {
    setSearchField(e.target.value);
  };

  const FIlterData = branchDetails.filter((item) =>
    item.name.toLowerCase().includes(searchField.toLowerCase())
  );

// pagination start
const [itemOffset, setItemOffset] = useState(0);

const itemsPerPage = 10;
const endOffset = itemOffset + itemsPerPage;
const currentItems = FIlterData.slice(itemOffset, endOffset);
const pageCount = Math.ceil(FIlterData.length / itemsPerPage);

// Invoke when user click to request another page.
const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % FIlterData.length;
  setItemOffset(newOffset);
};
// pagination end

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <span className="card-title">Branch Details</span>
                  </div>
                  <div className="col-md-4">
                    <div className="search-field ms-3 d-inline-flex">
                      <span class="mdi mdi-magnify"></span>
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Type to Search..."
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-end">
                    <Link to="/add-new-branch" className="link_to_add_branch d-block">
                      Add New Branch
                    </Link>
                  </div>
                </div>

                <div className="table-responsive mt-3">
                  <Table striped bordered hover className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Branch Code</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>zipcode</th>
                        <th>Status</th>
                        <th>Last Modified</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {branchDetails.length > 0 ? (
                        FIlterData.map((val, index) => {
                          const countryData = countryName?.find(
                            (value) =>
                              value.code == val.country ||
                              value.id == val.country
                          );
                          const stateData = stateName?.find(
                            (value) =>
                              value.code == val.state || value.id == val.state
                          );

                          return (
                            <tr key={val.id}>
                              <td>{index + 1}</td>
                              <td>{val.name}</td>
                              <td>{val.branch_code}</td>
                              <td>{val.address}</td>
                              <td>{val.city}</td>
                              {/* <td>{val.state}</td> */}
                              <td>{stateData?.default_name}</td>
                              {/* <td>{val.country}</td> */}
                              <td>{countryData?.name}</td>
                              <td>{val.zipcode}</td>
                              <td>{val.status == 1 ? "Active" : "Inactive"}</td>
                              <td>
                                {moment(val.updated_at).format(
                                  "DD-MM-YYYY hh:mm:ss a"
                                )}
                              </td>
                              <td>
                                <span
                                  className="mdi mdi-pencil"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleEdit(val.id)}
                                ></span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10}>No Data Found!</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                {/* pagination */}
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination mt-3 justify-content-end"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ManageBranch;
