import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";

export default function Login() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });

  // const login_details = localStorage.getItem("Login");
  // useEffect(() => {
  //   {login_details ? navigate('/dashboard') : navigate('/')}
  // },[login_details])

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(API_URL + "login", inputValue)
      .then((res) => {
        localStorage.setItem(
          "Login",
          JSON.stringify({
            login: true,
            token: res.data.accessToken,
          })
        );

        //set admin id
        const Token = JSON.parse(localStorage.getItem("Login"));
        const token = Token.token;
        const header = {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
          },
        };
        // alert(Token)
        axios.get(API_URL + "profile", header).then((resp) => {
          // alert(resp.data.data.id)
          if (resp.status == 200 && resp.data.data.id !== null) {
            localStorage.setItem("admin_id", resp.data.data.id);
          }
        });

        // navigate('/main')
        // toast.success('You are logged in')

        // setTimeout(() => navigate("/dashboard"), 200);
        setTimeout(()=>{
          localStorage.setItem("activeClass","1")
          navigate("/dashboard")
        },[200])
      })
      .catch((error) => {
        toast.error("Email or password is wrong, try again!");
        console.error("Error:", error);
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const hideShowValue = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5 pt-0">
                <div className="brand-logo">
                  {/* <img src="../../assets/images/logo.svg" /> */}
                  <img src="/team-icon.png" alt="logo" />
                  <span className="login_logo">TeamTrack</span>
                </div>
                <h4>Hello! let's get started</h4>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                <form className="pt-3">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      name="email"
                      value={inputValue.email}
                      onChange={handleInput}
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group position-relative">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control form-control-lg"
                      name="password"
                      value={inputValue.password}
                      onChange={handleInput}
                      placeholder="Password"
                    />
                    <i className={showPassword ? "mdi mdi-eye eyeIconLogin" : "mdi mdi-eye-off eyeIconLogin" }onClick={hideShowValue}></i>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={handleSubmit}
                    >
                      SIGN IN
                    </button>
                  </div>
                  {/* <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" />{" "}
                        Keep me signed in{" "}
                      </label>
                    </div>
                    <Link to="/forgot" className="auth-link text-black">
                      Forgot password?
                    </Link>
                  </div> */}

                  {/* <div className="text-center mt-4 font-weight-light">
                    {" "}
                    Don't have an account?{" "}
                    <Link to="/register" className="text-primary">
                      Create
                    </Link>
                  </div> */}
                </form>
              </div>
            </div>
            
          </div>
          
        </div>
        
        {/* <!-- content-wrapper ends --> */}
      </div>
      {/* <!-- page-body-wrapper ends --> */}
      <div className="footer_custom_text login_f"><p>Copyright © 2023 HashTag Labs, All rights reserved.</p></div>
    </div>
  );
}
